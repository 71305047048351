import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Icon from '../../components/Icon'
import { H2, H4 } from '../../styleElements'
import mq from '../../utils/mediaQuery'

const ContactCard = ({ title, img, desc, link, linkType, ...rest }) => {
  const cardFrame = () => (
    <>
      <Header>
        <Title>{title}</Title>
        <LogoImage fluid={img} objectFit="cover" objectPosition="50% 50%" />
      </Header>
      <Info>
        <DescText>{desc}</DescText>
        <LinkIcon size={24} name="next" />
      </Info>
    </>
  )

  switch (linkType) {
    case 'internal':
      return (
        <Link to={link} css={rootStyle} {...rest}>
          {cardFrame()}
        </Link>
      )
    case 'external':
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          css={rootStyle}
          {...rest}
        >
          {cardFrame()}
        </a>
      )
    case 'popup':
    default:
      return (
        <section css={rootStyle} {...rest}>
          {cardFrame()}
        </section>
      )
  }
}

ContactCard.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  desc: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkType: PropTypes.oneOf(['internal', 'external', 'popup']).isRequired
}

const rootStyle = theme => css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${theme.color.white};
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s linear;

  ${mq.md} {
    width: 100%;
    flex-direction: row;
  }

  ${mq.lg} {
    width: 47%;
    flex-direction: row;
  }

  &:hover {
    background-color: ${theme.color.gray3};
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  ${mq.md} {
    width: 50%;
    padding-right: 20px;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`

const Title = styled(H2)`
  color: ${props => props.theme.color.gray1};

  ${mq.md} {
    padding-right: 10px;
  }
`

const LogoImage = styled(Img)`
  width: 77px;
  height: 77px;

  ${mq.md} {
    flex-shrink: 0;
  }
`

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.md} {
    flex-shrink: 1;
  }
`

const DescText = styled(H4)`
  color: ${props => props.theme.color.primary1};
  max-width: 70%;

  ${mq.md} {
    max-width: 100%;
    padding-right: 10px;
  }
`

const LinkIcon = styled(Icon)`
  border: 1px solid ${props => props.theme.color.primary1};
  border-radius: 50%;

  ${mq.md} {
    flex-shrink: 0;
  }
`

export default ContactCard
